import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import QuizIcon from '@mui/icons-material/Quiz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

const Timeline = ({ id }) => {
  const [activeNodes, setActiveNodes] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          `https://training.progrhyming.com/platform/user/categories/${id}`
        );
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [id]);

  const handleNodeClick = (nodeId) => {
    setActiveNodes((prevActiveNodes) =>
      prevActiveNodes.includes(nodeId)
        ? prevActiveNodes.filter((n) => n !== nodeId)
        : [...prevActiveNodes, nodeId]
    );
  };

  return (
    <div style={styles.timelineContainer} className='timee'>
      {data.length > 0 ? (
        data.map((cat) => (
          <div key={cat.id}>
            <div onClick={() => handleNodeClick(cat.id)} style={styles.node}>
              <span style={styles.dot}></span>
              <span style={styles.nodeTitle}>{cat.name}</span>
              {activeNodes.includes(cat.id) ? (
                <ExpandLessIcon style={styles.icon} />
              ) : (
                <ExpandMoreIcon style={styles.icon} />
              )}
            </div>
            {activeNodes.includes(cat.id) && (
              <div style={styles.subTimeline}>
                {cat.timelines.length > 0 ? (
                  cat.timelines.map((pin) => (
                    <div key={pin.id} style={styles.node}>
                      <span style={styles.subDot}></span>
                      <span style={styles.subNodeTitle}>
                        {pin.quiz ? (
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <QuizIcon />
                            <p style={styles.text}>Quiz: {pin.quiz?.name || 'غير متوفر'}</p>
                          </div>
                        ) : pin.level ? (
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <CheckCircleIcon />
                            <p style={styles.text}>Level: {pin.level.name || 'غير متوفر'}</p>
                          </div>
                        ) : (
                          <p style={styles.noDataText}>لا توجد بيانات للمستوى أو الاختبار.</p>
                        )}
                      </span>
                    </div>
                  ))
                ) : (
                  <p style={styles.noDataText}>لا توجد بيانات للمستويات أو الاختبارات.</p>
                )}
              </div>
            )}
          </div>
        ))
      ) : (
        <p style={styles.noDataText}>لا توجد بيانات متاحة.</p>
      )}
    </div>
  );
};

// Styles
const styles = {
  timelineContainer: {
    padding: '10px', // قلل من الـ padding
    backgroundColor: '#f9f9f9',
    borderRadius: '12px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    maxWidth: '100%',
  },
  node: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '15px', // تقليل المسافة السفلية
    padding: '10px',
    borderRadius: '8px',
    transition: 'transform 0.3s, background-color 0.3s',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)',
  },
  nodeTitle: {
    fontWeight: 'bold',
    fontSize: '16px', // حجم أصغر قليلاً
    color: '#333',
    marginLeft: '10px',
  },
  subNodeTitle: {
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#555',
    marginLeft: '10px',
  },
  dot: {
    width: '12px',
    height: '12px',
    backgroundColor: '#007bff',
    borderRadius: '50%',
    border: '2px solid #007bff',
    marginLeft: '10px',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  subDot: {
    width: '8px',
    height: '8px',
    backgroundColor: '#ff5722',
    borderRadius: '50%',
    marginRight: '10px',
    marginLeft: '5px',
    border: '2px solid #ff5722',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  subTimeline: {
    marginLeft: '20px', // تقليل المسافة اليسرى
    marginTop: '5px', // تقليل المسافة العلوية
    paddingLeft: '10px',
    borderLeft: '2px solid #007bff',
    animation: 'fadeIn 0.5s ease-in-out',
  },
  icon: {
    marginLeft: '5px',
    color: '#007bff',
    fontSize: '18px', // حجم أصغر قليلاً
  },
  noDataText: {
    color: '#777',
    fontStyle: 'italic',
    marginLeft: '10px',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(-10px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },

  // Media query for mobile screens
  '@media (max-width: 600px)': {
    timelineContainer: {
      padding: '5px',
    },
    nodeTitle: {
      fontSize: '14px',
    },
    subNodeTitle: {
      fontSize: '12px',
    },
    dot: {
      width: '10px',
      height: '10px',
      marginLeft: '5px',
    },
    subDot: {
      width: '6px',
      height: '6px',
    },
    icon: {
      fontSize: '16px',
    },
  },
};
export default Timeline;