import React from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import "../css/responsive/footer.css";
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer>
      <div className="image">
        <img src={require("../images/PR.png")} height={"70px"} alt="" />
        {/* <img
          src={require("../images/ProgRyhming.png")}
          height={"30px"}
          alt=""
        /> */}
      </div>
      <div className="links">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "0d5aa4",
          }}
        >
          <h3>{t('home')}</h3>
        </Link>
        <Link
          to="/courses"
          style={{
            textDecoration: "none",
            color: "0d5aa4",
          }}
        >
          <h3>{t('courses')}</h3>
        </Link>
        <Link
          to="/aboutus"
          style={{
            textDecoration: "none",
            color: "0d5aa4",
          }}
        >
          <h3>{t('aboutus')}</h3>
        </Link>
        <h3>
          <Link
            to="/contactus"
            style={{
              textDecoration: "none",
              color: "0d5aa4",
            }}
          >
            {t('contactus')}
          </Link>
        </h3>
      </div>
      <div className="social">
        <a href="https://www.facebook.com/prorhyming/" target="blank">
          <img
            src={require("../images/facebook_5968764.png")}
            height={"40px"}
            alt=""
          />
        </a>
        <a href="https://t.me/PRcamps" target="blank">
          <img
            src={require("../images/telegram_5968804.png")}
            height={"40px"}
            alt=""
          />
        </a>
        <a href="https://www.linkedin.com/company/progrhyming" target="blank">
          <img
            src={require("../images/linkedin_5968789.png")}
            height={"40px"}
            alt=""
          />
        </a>
        <a href="https://wa.me/963993435475/?text=Hello" target="blank">
          <img
            src={require("../images/whatsapp_3536445.png")}
            height={"40px"}
            alt=""
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
