import React, { useEffect, useState } from "react";
import "../css/Course.css";
import TimeLine from "../components/TimeLine";
import Rating from "@mui/material/Rating";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Nav from "./Nav";
import Footer from "./Footer";
import FormDialog from "./Dialog";
import '../css/responsive/course.css'
import { useTranslation } from 'react-i18next';

const Course = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(
          `https://training.progrhyming.com/platform/user/courses/${courseId}`,
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzIwNDM5MzQxfQ.jyqX_-dEAp9oX8Ts2vx_QJ_29gZs6rGhNAjYnao5Nb0`,
            },
          }
        );
        setCourse(response.data.data);
        console.log("ali ", course);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching course details:", error);
        setError("Failed to load course details.");
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [courseId]);

  const sent = () => {
    if (!(course?.files && course.files.length > 0)) {
      navigate (`/course/${courseId}`)
    }
    else{
    const mapData = course.files.map((item) => ({
      url: item.url,
      name: item.name,
    }));
    navigate(`/video`, { state: { mapData } });
  };
}
  return (
    <div
      style={{
        background: "aliceblue",
      }}
    >
      <Nav />
      <div className="backg">
        <div className="rieghtCon">
          <div className="head">
            <h1>{course.name}</h1>
            <button className="order">
              <FormDialog id={course.id} />
            </button>
          </div>
          <div
            className="descr"
            style={{
              padding: "0px",
              textAlign: "left",
            }}
          >
            <p
              style={{
                textAlign: "left",
                margin: "0px",
                color :"#4478a8"
              }}
            >
              {course.description}
            </p>
          </div>
          <h1
            style={{
              color: "#f49f2b",
              margin : "0px"
            }}
            className="fuck"
          >
            {t('Learning journey')}
          </h1>
          <div className="time">
            <TimeLine id={course.id} />
          </div>
        </div>
        <div className="leftCon">
          <img src={`https://panel.progrhyming.com/storage/${course.image}`} height={"50%"} width={"80%"} alt="" />
          <div className="mid">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin :"0px"
              }}
            >
              <img
                src={require("../images/tag.png")}
                height={"95%"}
                width={"20%"}
                alt=""
                style={{
                  margin : "0px !important"
                }}
              />
              <h2
              style={{
                marginLeft : "10px"
              }}>{course.price} <span style={{
                fontSize : "15px"
              }}>S.P</span></h2>
            </div>
            <Rating
              name="half-rating-read"
              defaultValue={course.rate}
              precision={0.5}
              readOnly
            />
          </div>
          <div className="Button">
            <button
              className="But"
              onClick={() => {
                sent();
              }}
            >
              <img
                src={require("../images/watach now.png")}
                height="50px"
                width={"100%"}
                alt=""
              />
            </button>
            {/* <button className="But">
              <img src={require("../images/pdf.png")} height="50px" width={"100%"} alt="" />
            </button> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Course;
