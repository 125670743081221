import React, { useState, useEffect } from "react";
import "../css/nav.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // استيراد useTranslation من react-i18next
import "../css/responsive/Nav.css";
import Nav2 from "./Nav2";

const Nav = () => {
  const { t, i18n } = useTranslation(); // جلب الدالة t لاستخدام الترجمة
  const [activeLink, setActiveLink] = useState("/");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [language, setLanguage] = useState(i18n.language); // إضافة حالة لتحديد اللغة الحالية
  const location = useLocation();

  window.addEventListener("scroll", function () {
    const navbar = document.querySelector(".nav");
    if (window.scrollY > 0) {
      navbar.classList.add("scrolled"); // إضافة الكلاس عند التمرير لأسفل
    } else {
      navbar.classList.remove("scrolled"); // إزالة الكلاس عند العودة للأعلى
    }
  });

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // وظيفة التبديل بين اللغات
  const toggleLanguage = () => {
    const newLang = language === "en" ? "ar" : "en"; // إذا كانت إنجليزية نبدل إلى عربية والعكس
    setLanguage(newLang);
    i18n.changeLanguage(newLang); // تغيير اللغة في i18n
  };

  if (isMobile) {
    return <Nav2 />;
  }

  return (
    <nav>
      <div className="nav">
        <div className="section1">
          <img src={require("../images/PR.png")} height="70px" alt="" />
        </div>
        <div className="section2">
          <ul className="list">
            <Link to="/" className="Link">
              <li className={activeLink === "/" ? "active" : ""}>
                {t('home')} {/* ترجمة "Home" */}
              </li>
            </Link>
            <Link to="/Courses" className="Link">
              <li className={activeLink === "/Courses" ? "active" : ""}>
                {t('courses')} {/* ترجمة "Courses" */}
              </li>
            </Link>
            <Link to="/aboutus" className="Link">
              <li className={activeLink === "/aboutus" ? "active" : ""}>
                {t('aboutus')} {/* ترجمة "About Us" */}
              </li>
            </Link>
            <Link to="/Contactus" className="Link">
              <li className={activeLink === "/Contactus" ? "active" : ""}>
                {t('contactus')} {/* ترجمة "Contact Us" */}
              </li>
            </Link>
          </ul>
        </div>
        <div className="section3">
          {/* زر التبديل بين اللغات */}
          <button className="language-toggle-btn" onClick={toggleLanguage}>
            {language === "en" ? t('language') : t('language')} {/* ترجمة زر التبديل */}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Nav;