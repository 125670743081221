import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";  // استيراد Typography
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next'; // استيراد useTranslation من react-i18next

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  borderRadius: '4px',
}));

export default function TemporaryDrawer() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language);
  
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toggleLanguage = () => {
    const newLang = language === "en" ? "ar" : "en"; // إذا كانت إنجليزية نبدل إلى عربية والعكس
    setLanguage(newLang);
    i18n.changeLanguage(newLang); // تغيير اللغة في i18n
  };

  const DrawerList = (
    <Box 
      sx={{ 
        width: 250, 
        backgroundColor: "#f5f5f5", // لون خلفية فاتح
        height: "100%", 
        padding: "20px", 
        color: "#000" // لون النص موحد بالأسود
      }} 
      role="presentation" 
      onClick={toggleDrawer(false)}
    >
      <Box sx={{ marginBottom: "20px", textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#000" }}>
        <span style={{color : "#ff8f14"}}>PR</span><span style={{color : "#005ca4"}}>camp</span>
      </Box>
      <List>
        <StyledListItem button component={Link} to="/">
          <HomeIcon sx={{ color: "#ff8f14", marginRight: 2 }} />
          <ListItemText 
            primary={
              <Typography sx={{ color: "#005ca4", fontWeight: "bold" }}>
               {t('home')}
              </Typography>
            } 
          />
        </StyledListItem>
        <StyledListItem button component={Link} to="/Courses">
          <SchoolIcon sx={{ color: "#ff8f14", marginRight: 2 }} />
          <ListItemText 
            primary={
              <Typography sx={{ color: "#005ca4", fontWeight: "bold" }}>
                {t('courses')}
              </Typography>
            } 
          />
        </StyledListItem>
        <StyledListItem button component={Link} to="/aboutus">
          <InfoIcon sx={{ color: "#ff8f14", marginRight: 2 }} />
          <ListItemText 
            primary={
              <Typography sx={{ color: "#005ca4", fontWeight: "bold" }}>
                {t('aboutus')}
              </Typography>
            } 
          />
        </StyledListItem>
        <StyledListItem button component={Link} to="/Contactus">
          <ContactMailIcon sx={{ color: "#ff8f14", marginRight: 2 }} />
          <ListItemText 
            primary={
              <Typography sx={{ color: "#005ca4", fontWeight: "bold" }}>
                {t('contactus')}
              </Typography>
            } 
          />
        </StyledListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <img
          src={require("../images/justified_6631502.png")}
          height={"40px"}
          alt=""
        />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
        <div style={{
          marginBottom:"70vh"
        }} className="section3">
          {/* زر التبديل بين اللغات */}
          <button className="language-toggle-btn" onClick={toggleLanguage}>
            {language === "en" ? t('language') : t('language')} {/* ترجمة زر التبديل */}
          </button>
        </div>
      </Drawer>
    </div>
  );
}