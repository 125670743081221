import React from "react";
import "../css/About.css";
import "../css/responsive/About.css";
import Nav from "./Nav";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
const About = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        backgroundColor: "aliceblue",
      }}
    >
      <Nav />
      <div className="All">
        <div className="le">
          <h1 className="jjj">
            <span className="one">{t('abtit1')}</span>
            <br />
            <span className="two">{t('abtit2')}</span>
          </h1>
          <p>
            {t('dishome1')}{" "}
            <span
              style={{
                fontSize: "30px",
              }}
            >
              PR camp !
            </span>{" "}
              {t('about')}
          </p>
        </div>
        <div className="re">
          <img src={require("../images/camp.png")} className="img" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
