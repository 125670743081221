import React from "react";
import "../css/Contact.css";
import Map from "./Map";
import Nav from "./Nav";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
const Contactus = () => {
  const { t } = useTranslation();
  return (
    <div className="con">
        <Nav/>
      <h1 className="hh" style={{
        color:"#0d5aa4",
        margin: "20px",
      }}>{t('contact')}</h1>
      <div className="contanir">
        <div className="text">
          <div className="child">
            <img
              src={require("../images/earth.gif")}
              alt=""
              width={"50px"}
              height={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>{t('location')}</h3>
              <p>{t("location1")}</p>
            </div>
          </div>
          <div className="child">
            <img
              src={require("../images/24-hours-support.gif")}
              alt=""
              height={"50px"}
              width={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>{t("Phone")}</h3>
              <a href="tel:+963959532399" target="blanck">+963 959 532 399</a>
            </div>
          </div>
          <div className="child">
            <img
              src={require("../images/email.gif")}
              alt=""
              height={"50px"}
              width={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>{t("email")}</h3>
              <a href="mailto:progrhymingg@gmail.com">progrhymingg@gmail.com</a>
            </div>
          </div>
          <div className="child">
            <img
              src={require("../images/chat.gif")}
              alt=""
              height={"50px"}
              width={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>{t('whatsapp')}</h3>
              <p>+963 959 532 399</p>
            </div>
          </div>
        </div>
        <div className="Map">
          <Map />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Contactus;
