import React, { useState } from "react";
import ReactPlayer from "react-player";
import "../css/video.css";
import Footer from "../components/Footer";
import { IconButton } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Snackbar from "@mui/material/Snackbar";
import { useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Nav from "./Nav";
import "../css/responsive/Video.css";
import { useTranslation } from 'react-i18next';

const Video = () => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleItemClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const location = useLocation();
  const { mapData } = location.state;
  console.log(mapData);
  return (
    <div
      style={{
        background: "aliceblue",
      }}
    >
      <Nav />
      <div className="page-container">
        <div className="video-container">
          <div className="video-main">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={`https://panel.progrhyming.com/storage/${mapData[0].url}`}
                controls
                width="100%"
                height="100%"
                playing={false}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
            
              <h1 className="video-title">{mapData[0].name}</h1>
            
          </div>
          <div className="video-list">
            <h2
              style={{
                color: "#0d5aa4",
              }}
            >
              {t('more')}
            </h2>
            <ul>
              <li onClick={handleItemClick}>
                <IconButton className="video-icon">
                  <PlayArrowIcon
                    sx={{
                      color: "#0d5aa4",
                    }}
                  />
                </IconButton>
                <div className="video-details">
                  <span>{t('video1')}</span>
                  <IconButton className="lock-icon">
                    <LockIcon
                      sx={{
                        color: "#d32f2f",
                      }}
                    />
                  </IconButton>
                </div>
              </li>
              <li onClick={handleItemClick}>
                <IconButton className="video-icon">
                  <PlayArrowIcon
                    sx={{
                      color: "#0d5aa4",
                    }}
                  />
                </IconButton>
                <div className="video-details">
                  <span>{t('video2')}</span>
                  <IconButton className="lock-icon">
                    <LockIcon
                      sx={{
                        color: "#d32f2f",
                      }}
                    />
                  </IconButton>
                </div>
              </li>
              <li onClick={handleItemClick}>
                <IconButton className="video-icon">
                  <PlayArrowIcon
                    sx={{
                      color: "#0d5aa4",
                    }}
                  />
                </IconButton>
                <div className="video-details">
                  <span>{t('video3')}</span>
                  <IconButton className="lock-icon">
                    <LockIcon
                      sx={{
                        color: "#d32f2f",
                      }}
                    />
                  </IconButton>
                </div>
              </li>
            </ul>

            <Snackbar
              open={open}
              autoHideDuration={4000}
              anchorOrigin={{ vertical: 'center', horizontal: 'center'}}
              onClose={handleClose}
              sx={{
                width : "50%",
                fontSize : "40px"
              }}
            >
              <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
                <p>Order This course !!</p>
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Video;
