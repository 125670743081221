import React from "react";
import Nav from "./Nav";
import "../css/responsive/card.css";
import "../css/card.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom"; // استيراد useNavigate
import noDataImage from "../images/folder.png";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Lottie from "lottie-react";
import Loader from "../Loader.json";
import { useTranslation } from 'react-i18next';
const CoursesViwe = () => {
  const [sections, setSection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // تهيئة useNavigate
  const { t } = useTranslation();
  useEffect(() => {
    axios
      .get("https://training.progrhyming.com/platform/user/sections/", {
        // headers: {
        //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzIwNDM5MzQxfQ.jyqX_-dEAp9oX8Ts2vx_QJ_29gZs6rGhNAjYnao5Nb0`,
        // },
      })
      .then((response) => {
        console.log(response.data.data);

        setLoading(false);
        if (response.status === 200) {
          setSection(response.data.data);
          setLoading(false);
        } else console.log("erore");
      })
      .catch((error) => {
        console.log(error);
        // setError(error);
        setLoading(false);
      });
  }, []);

  const handleCardClick = (sectionId, sectionTitle) => {
    navigate(`/courses/${sectionId}`, { state: { sectionTitle } });
  };
  return (
    <div className="vh" style={{
      backgroundColor : "aliceblue"
    }}>
      <div className="OurCur">
        <Nav />
        <div className="Types">
          <h1 className="hh" style={{ color: "#0d5aa4", marginTop: "10px" }}>
            {t('our-courses')}
          </h1>
          <div className="ali">
            {loading ? (
              <div style={{ width: "50%", margin: "auto", display: "block" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 0 150px 0",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      flexDirection: "column",
                      height: "30vh",
                    }}
                  >
                    <Lottie
                      animationData={Loader}
                      loop={true}
                      autoplay={true}
                    />
                  </div>
                </Box>
              </div>
            ) : error ? (
              <div>{error}</div>
            ) : sections.length === 0 ? (
              <div className="no-data">
                <img
                  src={noDataImage}
                  alt="No courses available"
                  style={{ width: "50%", margin: "auto", display: "block" }}
                />
                <p
                  style={{
                    textAlign: "center",
                    color: "#0d5aa4",
                  }}
                >
                  No courses available.
                </p>
              </div>
            ) : (
              sections.map((section, index) => (
                <div
                  className="CARD"
                  key={index}
                  onClick={() => handleCardClick(section.id, section.title)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={`https://panel.progrhyming.com/storage/${section.image}`}
                    alt=""
                  />
                  <h1>{section.title}</h1>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoursesViwe;
