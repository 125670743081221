import "../css/AD.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import noDataImage from "../images/folder.png";
import Lottie from "lottie-react";
import Loader from "../Loader.json";
import { useTranslation } from 'react-i18next';

export default function SimpleSlider() {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation(); // استيراد useTranslation

  useEffect(() => {
    axios
      .get("https://training.progrhyming.com/platform/user/advertisements/")
      .then((response) => {
        setAds(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplaySpeed: 2500,
  };

  const isRtl = i18n.language === 'ar'; // تحديد ما إذا كانت اللغة عربية

  return (
    <div className={`all ${isRtl ? 'rtl' : 'ltr'}`}>
      <h1 className="hh"> {t("adstitle")} :</h1>
      {loading ? (
        <div style={{ width: "50%", margin: "auto", display: "block" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0 0 150px 0",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
                height: "30vh",
              }}
            >
              <Lottie animationData={Loader} loop={true} autoplay={true} />
            </div>
          </Box>
        </div>
      ) : ads.length === 0 ? (
        <div className="no-data">
          <img
            src={require("../images/question_5512471.png")}
            alt="no data"
            style={{ width: "300px", margin: "auto", display: "block", height: "300px", marginTop: "30px" }}
          />
          <p
            style={{
              textAlign: "center",
              color: "#0d5aa4",
              fontSize: "20px",
              margin: "40px",
            }}
          >
            {t("noads")}
          </p>
        </div>
      ) : (
        <Slider {...settings} className="slider">
          {ads.map((ad, index) => (
            <div key={index} className="Add">
              <img
                src={`https://panel.progrhyming.com/storage/${ad.image}`}
                alt={ad.title ? ad.title : "Ad image"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}