import React from "react";
import "../css/Home.css";
import Nav from "../components/Nav";
import SimpleSlider from "./SimpleSlider";
import Sponser from "./Sponser";
import Services from "../components/Services";
import "../css/responsive/Home.css";
import CourseItem from "./CourseItem";
import Staff from "../components/Staff";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import MostRate from "./MostRate";
import { Link } from "react-router-dom";
const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="ap">
      <Nav />
      <div className="back">
        <video src="/is.mp4" autoPlay muted id="background-video"></video>
        <div className="container">
          <div className="left">
            <h1 style={{ fontSize: "48px", fontWeight: "bold" }} className="hh">
              {t("titlehome")}
            </h1>
            <p>
              {t("dishome1")}{" "}
              <span
                style={{
                  color: "#0d5aa4",
                  fontSize: "35px",
                  marginLeft: "12px",
                  fontWeight: "bold",
                }}
              >
                Prog
              </span>
              <span
                style={{
                  color: "#f4942b",
                  fontSize: "35px",
                  marginRight: "12px",
                  fontWeight: "bold",
                }}
              >
                Rhyming
              </span>{" "}
              {t("dishome2")}
            </p>
            <Link
              to="/courses"
              style={{
                textDecoration: "none",
              }}
            >
              <button className="alkhal">{t("buthome")}</button>
            </Link>
          </div>
          {/* <div className="rieght">
            <img
              src={require("../images/1.png")}
              alt="fs"
              height="100%"
              width="100%"
              style={{ borderRadius: "20px" }}
            />
          </div> */}
        </div>
      </div>
      <div>
        <SimpleSlider />
        <Services />
        <MostRate />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
