import React from "react";
import "../css/Services.css";
import "../css/responsive/sirveses.css";
import { useTranslation } from "react-i18next"; // استيراد useTranslation

const Services = () => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{}}>
      <h1 className="hh">{t("sertitle")} :</h1>
      <div className="container1">
        <div className="item">
          <img
            src={require("../images/chat_777724.png")}
            alt=""
            height={"90px"}
          />
          <div className="cont">
            <h2>{t("ser1")}</h2>
            <p>
              {t("sertit1")}
            </p>
          </div>
        </div>
        <div className="item">
          <img
            src={require("../images/online-meeting_5070287.png")}
            alt=""
            height={"90px"}
          />
          <div className="cont">
            <h2>{t("ser2")}</h2>
            <p>
              {t("sertit2")}
            </p>
          </div>
        </div>
        <div className="item">
          <img
            src={require("../images/web_3138720.png")}
            alt=""
            height={"90px"}
          />
          <div className="cont">
            <h2>{t("ser3")}</h2>
            <p>
              {t("sertit3")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
