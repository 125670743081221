import React from "react";
import Test from "../components/Test";
const Nav2 = () => {
  return (
    <div>
      <div
        className="nav"
        style={{
          justifyContent: "space-between !important",
          gap: "50% !important",
          margin: "0px !important",
          textAlign:"left"
        }}
      >
        <div className="section1">
          <img src={require("../images/PR.png")} height="80px" alt="" />
        </div>
        <div className="section2">
          <Test />
        </div>
      </div>
    </div>
  );
};

export default Nav2;
