import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import CoursesView from "./components/CoursesViwe";
import Course from "./components/Course";
import Video from './components/Video';
import CourseList from "./components/CourseList";
import Lottie from "lottie-react";
import animationData from "./Animation - 1728137697218.json";
import './css/Home.css';
import './i18n'; // استيراد إعداد i18n
import { useTranslation } from 'react-i18next'; // استيراد useTranslation
import { createTheme, ThemeProvider } from "@mui/material/styles";

const App = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const hasSeenAnimation = sessionStorage.getItem('hasSeenAnimation');

    if (!hasSeenAnimation) {
      
      const timer = setTimeout(() => {
        setLoading(false);
        sessionStorage.setItem('hasSeenAnimation', 'true');
      }, 3000);
      setLoading(true);
      return () => clearTimeout(timer);
    } else {
      
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Outfit ,Cairo, sans-serif',
      fontSize: 14,
    },
  });
  if (loading === null) {
  
    return null;
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Lottie animationData={animationData} loop={false} autoplay={true} />
        <img className="responsive-image" src={require('../src/images/ProgRyhming.png')} alt="" />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/courses" element={<CoursesView />} />
      <Route path="/course/:courseId" element={<Course />} />
      <Route path="/video" element={<Video />} />
      <Route path="/courses/:sectionId" element={<CourseList />} />
    </Routes>
    </ThemeProvider>
  );
};

export default App;
